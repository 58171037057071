import React from 'react';
import { FaGithub } from 'react-icons/fa';

const Projects = () => {
  return (
    <div id="projects" className="projects-container">
      <h2 className="heading">Projects</h2>
      <div className="project-cards">
        {projects.map((project, index) => (
          <div key={index} className="project-card">
            <div className="project-header">
              <h3>{project.title}</h3>
              <div className="icons">
                <a href={project.githubLink} target="_blank" rel="noopener noreferrer">
                  <FaGithub className="icon-proj" />
                </a>
              </div>
            </div>
            <div className="project-details">
              <p>{project.description}</p>
              <div className="tech-stack">
                Skills: {project.skills.join(' | ')}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const projects = [
  {
    title: 'Sixth Sense',
    githubLink: 'https://github.com/cse110-sp23-group6/SixthSense',
    description: 'A website that tells your fortune based on your inner "SixthSense".',
    skills: ['HTML', 'CSS', 'JavaScript', 'CI/CD Pipeline', 'Agile Principles', 'UI/UX', 'Leadership']
  },
  {
    title: 'Song4U',
    githubLink: 'https://github.com/namburiamit/Song4U',
    description: 'A project that detects percentages of "Happy" and "Sad" emotions and creates a playlist of 10 songs on the Spotify app.',
    skills: ['Computer Vision', 'Web Scraping', 'Auth0', 'Training ML Models']
  },
  {
    title: 'MusicPal',
    githubLink: 'https://github.com/namburiamit/MusicPal',
    description: 'A music recommendation and summarization system with features like music playlist generation, adding to queue based on the current song, and summarization.',
    skills: ['Spotify API', 'Python', 'Flask', 'React']
  },
  {
    title: 'AutoQueue',
    githubLink: 'https://github.com/namburiamit/CSES-Project-SP22',
    description: 'A Discord bot that detects the current playing song on Spotify and adds similar songs to the queue.',
    skills: ['Scikit-Learn', 'KMeans clustering', 'Spotify API']
  }
];

export default Projects;
