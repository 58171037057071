import React from 'react';
import Introduction from './components/Introduction';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Contact from './components/Contact';

const App = () => {
  return (
    <div>
      <Introduction />
      <Experience />
      <Projects />
      <Contact />
    </div>
  );
};


export default App;
