import React, { useEffect, useState } from 'react';

const Experience = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    const checkVisibility = () => {
      const windowHeight = window.innerHeight;
      const sectionTop = document.getElementById('experience').getBoundingClientRect().top;

      setIsVisible(sectionTop < windowHeight * 0.8);
    };

    window.addEventListener('scroll', checkVisibility);
    return () => window.removeEventListener('scroll', checkVisibility);
  }, []);

  const toggleDropdown = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <div id="experience" className="experience-section">
        <h2 className="heading">Experience</h2>
        <div className="experiences-list">
          {experiences.map((exp, index) => (
            <div className={`experience-entry ${isVisible ? 'visible' : ''}`} key={index}>
              <h3 className="experience-title">{exp.company}</h3>
              <p className="experience-role">
                {exp.role} - <span className="experience-dates">{exp.location} | {exp.date}</span>
              </p>
              <p className="experience-skills">{exp.skills.join(" | ")}</p>
              <ul className="experience-details">
                {exp.details.map((detail, idx) => <li key={idx}>{detail}</li>)}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div id="publications" className="publications-section">
        <h2 className="heading">Publications</h2>
        <ul className="publications-list">
          {publications.map((pub, index) => (
            <li key={index} className="publication-item">
              <div className="publication-title" onClick={() => toggleDropdown(index)}>
                {pub.title} <span className="dropdown-icon">{activeIndex === index ? '▲' : '▼'}</span>
              </div>
              {activeIndex === index && (
                <div className="publication-details">
                  <p className="publication-authors">{pub.authors} - {pub.year}</p>
                  <p className="publication-info">{pub.conference} - {pub.status}</p>
                  {pub.link && <a href={pub.link} target="_blank" rel="noopener noreferrer" className="publication-link">Paper Link</a>}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

const experiences = [
  {
    company: "McAuley Lab",
    role: "Multimodal AI Research Assistant",
    location: "San Diego, CA",
    date: "Jan 2024 – Present",
    skills: ["Generative Models", "Large Language Models", "Representation Learning"],
    details: [
      "Multimodal AI (FUTGA Model): Created and fine-tuned a generative model for fine-grained, time-aware music understanding using Large Language Models and generative augmentation techniques.",
      "Helped with LoRa finetuning of the SALMONN-7B backbone model on 2 NVIDIA RTX A6000 GPU.",
      "Conducted extensive evaluations using BLEU, METEOR, ROUGE, and BERT-score metrics, validating the effectiveness of the proposed model.",
      "LLM Finetuning (Video Visual Prompting): Working on fine-tuning VTime LLM model to improve on Video Visual Prompting techniques.",
      "Building Proactive Recommendation Systems for Netflix."
    ]
  },
  {
    company: "Fair Data Informatics (FDI) Lab",
    role: "Student Software Engineer",
    location: "San Diego, CA",
    date: "March 2023 – Present",
    skills: ["Angular.js", "Pandas", "NumPy", "Scikit-learn"],
    details: [
      "Redesigning Foundry Dashboard architecture, a scalable ETL system for biomedical curators, with Angular.js, achieving a 40% increase in processing speed.",
      "Working on developing automated resource ingestion protocol for different clients hosted on EC2.",
      "Researched rigor and reproducibility of published work by analyzing tool performance using Pandas, NumPy, and Scikit-learn."
    ]
  },
  {
    company: "Computer Science and Engineering @ UC San Diego",
    role: "Instructional Assistant (IA/Tutor)",
    location: "San Diego, CA",
    date: "April 2023 – Present",
    skills: ["Advanced Data Structures", "Tutoring"],
    details: [
      "Tutoring for upper division Advanced Data Structures course (CSE 100) with an approval rate of 100%.",
      "Holding 8 tutor hours weekly for a class size of 500 and accepting approximately 350 tickets."
    ]
  },
  {
    company: "Orektic Solutions LLP",
    role: "Software Engineer Intern",
    location: "Remote",
    date: "June 2023 – Sep 2023",
    skills: ["TypeScript", "Cucumber.js", "Selenium Driver", "REST APIs", "Cypress"],
    details: [
      "Built a UI Testing framework for PWR-Apps using TypeScript, Cucumber.js, and Selenium Driver.",
      "Worked with REST APIs using Axios library to retrieve User Token, Information, and Instance details.",
      "Automated Test Suite runs using Cypress which increased efficiency by 42%.",
      "Performed cross-browser testing with automatic report generation in Microsoft Teams and Jira to track bugs."
    ]
  },
  {
    company: "IPMD, Inc.",
    role: "Front-End Developer Intern (Remote)",
    location: "Berkeley, CA",
    date: "July 2022 – Sep 2022",
    skills: ["React.js", "Typescript", "Node.js"],
    details: [
      "Developed a comprehensive web application for Project M, utilizing React.js to enhance UI/UX.",
      "Designed and implemented dynamic, responsive webpages using Typescript and Node.js."
    ]
  }
];

const publications = [
  {
    title: "Futga: Towards Fine-grained Music Understanding through Temporally-enhanced Generative Augmentation",
    authors: "Junda Wu, Amit Namburi, Jiaheg Dai, Carol Chen, Julian McAuley",
    year: "2024",
    conference: "NLP4MUSA (ACL submission) / ICASSP 2025",
    status: "Under Review",
    link: "https://arxiv.org/abs/2407.20445"
  },
  {
    title: "CoLLAP: Contrastive Long-form Language-Audio Pretraining with Musical Temporal Structure Augmentation",
    authors: "Warren Li, Junda Wu, Amit Namburi, Caron Cheng, Julian McAuley",
    year: "2024",
    conference: "ICASSP(IEEE International Conference on Acoustics, Speech, and Signal Processing) 2025",
    status: "Under Review",
    link: ""
  },
  {
    title: "CV - Visual Prompting Paper",
    authors: "Aaron Chang, Junda Wu, Amit Namburi, Julian McAuley",
    year: "2024",
    conference: "CVPR 2025 (Tentative)",
    status: "In Progress",
    link: ""
  },
  
  {
    title: "Proactive Recommendation Systems Project for Netflix",
    authors: "TBA",
    year: "2024",
    conference: "TBA",
    status: "In Progress",
    link: ""
  }
];

export default Experience;
