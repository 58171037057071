import logo from './new-photo.jpg';

// import logo from '../images/csesphoto.png'; 

import { FaGithub, FaLinkedin } from 'react-icons/fa'; // Import GitHub and LinkedIn icons

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  section.scrollIntoView({ behavior: 'smooth' });
};

const Introduction = () => {
  return (
    <div id="introduction">
      <div className="introduction-container">

        <div>
          <nav>
            <ul>
              <li>
                <a href="#introduction" onClick={() => scrollToSection('introduction')}>
                  Home
                </a>
              </li>
              <li>
                <a href="#experience" onClick={() => scrollToSection('experience')}>
                  Experience
                </a>
              </li>
              <li>
                <a href="#projects" onClick={() => scrollToSection('projects')}>
                  Projects
                </a>
              </li>
              <li>
                <a href="#contact" onClick={() => scrollToSection('contact')}>
                  Contact
                </a>
              </li>
            </ul>
          </nav>
          <h3 className='intro'>🔱 CS @ UC San Diego 🔱 </h3>
          <div className="social-icons">
            <a class href="https://github.com/namburiamit" target="_blank" rel="noopener noreferrer">
              <FaGithub className="icon github-icon" />
            </a>
            <a href="https://www.linkedin.com/in/amit-namburi/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin className="icon linkedin-icon" />
            </a>
          </div>
        </div>

        <div className="typing-container">
          <div className="typing">Hey, I'm <span>Amit Namburi</span></div>
        </div>

        <div className="about-me-container">
          <div className="image-container">
            <img src={logo} alt="logo" />
          </div>
          <div className="description-container">
            <h3 class="about">Web Developer/ ML Enthusiast </h3>
            <p className="fade-in">
            I'm Amit Namburi, a Senior at <span>UC San Diego</span> majoring in Computer Science.
            My research focuses in the field of Multimodal AI and machine's music understanding
            in natural language, and imagine visuals. An important part of my research is in representation
            learning, particularly in bridging the semantic gap between music and multi-modal media.
            With McAuley Lab, I'm currently delving deeper into large LLMs and fine-tuning them to cater
            specific needs of either better music understanding or video visual prompting.
 I am passionate about leveraging new technologies to address complex challenges and drive innovation in the field of AI.


            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
